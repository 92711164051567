











































import { BasicPaymentViewItem } from "@/types/Payment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TickIcon from "@/assets/icons/saxcons/tick-circle-linear.svg";
import WarningIcon from "@/assets/icons/saxcons/info-circle-linear.svg";
import ErrorIcon from "@/assets/icons/saxcons/danger-linear.svg";
import NotStartedIcon from "@/assets/icons/saxcons/stop-circle-linear.svg";
import ProcessingIcon from "@/assets/icons/saxcons/video-circle-linear.svg";

@Component({
  name: "payment-status",
  components: {
    TickIcon,
    WarningIcon,
    ErrorIcon,
    NotStartedIcon,
    ProcessingIcon
  }
})
export default class PaymentStatus extends Vue {
  @Prop({ required: true })
  item!: BasicPaymentViewItem | null;
}
