






































import {
  BasicPaymentViewItem,
  CheckoutPaymentMethod,
  CommonPaymentMethod,
  StripeCardPaymentMethod
} from "@/types/Payment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import StripeBankCard from "./StripeBankCard.vue";
import StripeCard from "./StripeCard.vue";
import StripeCheckoutCard from "./StripeCheckoutCard.vue";

@Component({
  name: "table-payment-method",
  components: {
    StripeCard,
    StripeBankCard,
    StripeCheckoutCard
  }
})
export default class TablePaymentMethod extends Vue {
  @Prop({ required: true })
  item!: BasicPaymentViewItem | null;

  @Prop({ required: false, default: null })
  defaultPaymentMethod!: CommonPaymentMethod | null;

  @Prop({ required: true })
  isPaymentMethodLoad!: boolean;

  @Prop({ required: false, default: true })
  showCashPayment!: boolean;

  @Prop({ required: false, default: 135 })
  minWidth!: number;

  get componentToShow(): "card" | "bank" | "checkout" {
    if (this.paymentMethod !== null) {
      if ((this.paymentMethod as StripeCardPaymentMethod).brand) {
        return "card";
      } else if ((this.paymentMethod as CheckoutPaymentMethod).isCheckout) {
        return "checkout";
      }
    }
    return "bank";
  }

  get paymentMethod(): CommonPaymentMethod | null {
    if (this.item?.status === "notStarted") {
      return this.defaultPaymentMethod;
    } else if (this.item) {
      return this.item.paymentMethod;
    }
    return null;
  }

  get paidInCash(): boolean {
    if (this.item !== null && this.item !== undefined) {
      return this.item.paidInCash || false;
    }
    return false;
  }
}
