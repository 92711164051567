








import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({
  name: "stripe-checkout-card"
})
export default class StripeCheckoutCard extends Vue {
  @Prop({ required: false, default: false })
  dense!: boolean;
}
